import React, { startTransition, useLayoutEffect, useRef, useState } from 'react';
import * as Select from '@radix-ui/react-select';
import { clsx } from 'clsx';
import { motion, MotionValue, useScroll, useTransform } from 'framer-motion';

import { CircleButton } from '@dandapani/web/ui/circle-button';
import { Icon } from '@dandapani/web/ui/icons';
import { TextSeparator } from '@dandapani/web/ui/text-separator';

function useParallax(
  value: MotionValue<number>,
  { containerHeight, elementHeight }: { containerHeight: number; elementHeight: number }
) {
  const inflectionPoint = (containerHeight - elementHeight) / 2;
  return useTransform(value, [0, 0.4, 0.6, 1], [containerHeight, inflectionPoint, inflectionPoint, -containerHeight]);
}

function Mandala({ children }: React.PropsWithChildren) {
  const containerRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLImageElement>(null);

  const { scrollYProgress } = useScroll({ target: containerRef });
  const [containerHeight, setContainerHeight] = useState(0);
  const [elementHeight, setElementHeight] = useState(0);

  const y = useParallax(scrollYProgress, { containerHeight, elementHeight });

  useLayoutEffect(() => {
    if (containerRef.current && elementRef.current) {
      const observer = new ResizeObserver((entries) => {
        startTransition(() => {
          if (entries[0]) {
            setContainerHeight(entries[0].contentRect.height);
          }

          if (entries[1]) {
            setElementHeight(entries[1].contentRect.height);
          }
        });
      });
      observer.observe(containerRef.current);
      observer.observe(elementRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [setContainerHeight]);

  return (
    <div
      className="m-4 aspect-square rounded-full bg-gray-900 lg:sticky lg:top-[calc(var(--header-height)_+_2rem)]"
      aria-hidden
    >
      <div className="relative h-full overflow-hidden" ref={containerRef}>
        <span className="absolute left-0 top-0 font-serif font-bold italic text-gray-900">(02)</span>
        <div className="absolute left-1/2 -translate-x-1/2">
          <motion.div style={{ y }}>
            <div ref={elementRef}>{children}</div>
          </motion.div>
        </div>
        <div className="h-8 w-8 rounded-full border-2 border-gray-500" />
      </div>
    </div>
  );
}

export function ProblemStatement({ children }: React.PropsWithChildren) {
  const [value, setValue] = useState('anxiety');

  return (
    <section className="bg-gray-500 py-16 lg:py-32">
      <div className="layout grid auto-rows-auto gap-24 lg:grid-cols-2 lg:grid-rows-1">
        <div className="col-start-1">
          <span className="sr-only">Second mandala ring</span>
          <Mandala>{children}</Mandala>
        </div>
        <div className="lg:col-start-2">
          <h3 className="font-serif text-2.5xl">
            I struggle with
            <br />
            <Select.Root value={value} onValueChange={setValue}>
              <Select.Trigger
                className="inline-flex items-center gap-2 text-red-500 outline-none hover:text-opacity-90 focus:rounded-md focus:ring-2 focus:ring-ring focus:ring-offset-2"
                aria-label="Choose what worries you to learn more what Dandapani thinks"
              >
                <Select.Icon className="grid h-9 w-9 place-content-center rounded-full border-2 border-red-500">
                  <Icon icon="chevron" dir="bottom" className="h-4 w-4 text-red-500" />
                </Select.Icon>
                <Select.Value />
              </Select.Trigger>
              <Select.Portal>
                <Select.Content
                  className="min-w-52 overflow-hidden border border-gray-600 bg-gray-500 p-4 data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
                  position="popper"
                >
                  <Select.ScrollUpButton className="flex h-6 cursor-default items-center justify-center">
                    <Icon icon="chevron" className="h-4 w-4 text-red-500" dir="top" />
                  </Select.ScrollUpButton>
                  <Select.Viewport className="grid grid-flow-row gap-3">
                    <SelectItem value="anxiety">anxiety</SelectItem>
                    <SelectItem value="fear">fear</SelectItem>
                    <SelectItem value="stress">stress</SelectItem>
                    <SelectItem value="depression">depression</SelectItem>
                  </Select.Viewport>
                  <Select.ScrollDownButton className="flex h-6 cursor-default items-center justify-center">
                    <Icon icon="chevron" className="h-4 w-4 text-red-500" dir="bottom" />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </h3>
          <ul className="mt-9 lg:mt-16">
            <DandapaniThoughts />
          </ul>
        </div>
      </div>
    </section>
  );
}

const SelectItem = React.forwardRef<
  HTMLDivElement,
  Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> & { value: string }
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Select.Item
      className={clsx(
        'relative select-none items-center font-serif text-2xl data-[disabled]:pointer-events-none data-[disabled]:text-gray-800 data-[highlighted]:!text-red-400 data-[state="checked"]:text-red-500 data-[highlighted]:outline-none',
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  );
});

function DandapaniThoughts() {
  return (
    <li>
      <TextSeparator>Dandapani’s Thoughts:</TextSeparator>
      <p>
        To address anxiety, I would recommend engaging in focused awareness meditation, a practice that trains your mind
        to remain calm and centered. By channeling your thoughts and energy towards a singular point of focus, you can
        gradually diminish the influence of anxiety on your mental state. Introducing mindful breathing techniques can
        also aid in grounding your attention and fostering inner tranquility.
      </p>
      <ul className="ml-24 mt-10 grid grid-flow-row gap-10 sm:ml-0 sm:grid-cols-2 md:ml-32 lg:ml-0">
        <li className="flex justify-end sm:justify-start">
          <CircleButton href="/" className="w-full xs:w-fit">
            Read an Article on Anxiety
          </CircleButton>
        </li>
        <li className="flex justify-end sm:justify-start">
          <CircleButton href="/" className="w-full xs:w-fit">
            Watch a Video on Anxiety
          </CircleButton>
        </li>
        <li className="sm:col-start-2">
          <p className="font-serif font-bold">
            Anxiety fades as we train our mind to stay present, releasing the grip of uncertainty.
          </p>
        </li>
      </ul>
    </li>
  );
}
